<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title" v-if="!updateState">
            <v-icon>school</v-icon> &nbsp; Add School Information
          </v-card-title>
          <v-card-title class="title" v-else>
            <v-icon>school</v-icon> &nbsp; Edit School Information
          </v-card-title>
          <v-card-text>
            <v-form
              v-model="company.validation.valid"
              ref="companyInfoForm"
              lazy-validation
            >
              <v-timeline class="user_add_form" align-top dense>
                <v-timeline-item color="red" small>
                  <v-layout pt-3>
                    <v-flex>
                      <strong>Services</strong>
                      <div class="caption">
                        Select services subscription for the company.
                      </div>
                      <v-flex xs11>
                        <v-combobox
                          :disabled="!$auth.isSuper() && updateState"
                          hint="Required basic permissions would be added to the services by default."
                          multiple
                          v-model="selectedServices"
                          :items="services"
                          :rules="company.validation.role"
                          item-text="name"
                          chips
                          icon="account_circle"
                          label="Select services."
                        >
                          <template v-slot:selection="service">
                            <v-chip
                              :input-value="service.selected"
                              color="primary"
                              :key="JSON.stringify(selectedServices.name)"
                              class="v-chip--select-multi"
                            >
                              <v-avatar>
                                <v-icon>assignment_ind</v-icon>
                              </v-avatar>
                              {{ service.item.name }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="pink lighten-1" small>
                  <v-layout wrap pt-3>
                    <v-flex xs8>
                      <strong>General Info.</strong>
                      <div class="caption">Basic company informations.</div>
                      <v-flex pt-3 xs11>
                        <v-container style="padding: 0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex xs6>
                              <v-text-field
                                :disabled="!selectedServices.length"
                                solo
                                autocomplete="off"
                                label="Company Name*"
                                required
                                class="pa-0"
                                v-model="form.name"
                                name="name"
                                :rules="company.validation.fnameRule"
                                :error-messages="form.errors.get('name')"
                              />
                              <DisplayError
                                errorName="name"
                                :displayError="displayError"
                              />
                            </v-flex>

                            <v-flex sm2>
                              <v-text-field
                                solo
                                autocomplete="off"
                                label="Short Code*"
                                :disabled="
                                  !selectedServices.length ||
                                  (!$auth.isSuper() && updateState)
                                "
                                required
                                class="pa-0"
                                v-model="form.short_code"
                                name="rank"
                                :error-messages="form.errors.get('short_code')"
                              />
                              <DisplayError
                                errorName="short_code"
                                :displayError="displayError"
                              />
                            </v-flex>

                            <v-flex sm4>
                              <v-text-field
                                solo
                                autocomplete="off"
                                label="Domain*"
                                :disabled="
                                  !selectedServices.length ||
                                  (!$auth.isSuper() && updateState)
                                "
                                required
                                class="pa-0"
                                v-model="form.domain"
                                name="rank"
                                :error-messages="form.errors.get('domain')"
                              />
                              <DisplayError
                                errorName="domain"
                                :displayError="displayError"
                              />
                            </v-flex>
                            <v-flex xs12>
                              <v-text-field
                                solo
                                autocomplete="off"
                                label="Company Slogan"
                                :disabled="!selectedServices.length"
                                required
                                class="pa-0"
                                v-model="form.slogan"
                                name="rank"
                                :error-messages="form.errors.get('slogan')"
                              />
                            </v-flex>
                            <v-flex xs5>
                              <v-calendar-field
                                id-val="established_date_school"
                                v-model="form.established_date"
                                label="Established Date"
                              >
                              </v-calendar-field>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>

                    <v-flex pt-2 xs3>
                      <v-card>
                        <v-card-text>
                          <img
                            :src="cropperImg.imgSrc"
                            v-if="cropperImg.imgSrc"
                            height="150"
                          />
                          <img
                            :src="'https://image.flaticon.com/icons/svg/138/138570.svg'"
                            height="150"
                            v-else
                          />
                          <input
                            type="file"
                            style="display: none"
                            ref="image"
                            accept="image/*"
                            @change="onFilePicked"
                          />
                          <v-btn
                            v-if="cropperImg.imgSrc"
                            fab
                            small
                            bottom
                            right
                            absolute
                            @click.native.stop="resetImagePicker(1)"
                            style="z-index: 1"
                            color="red white--text"
                          >
                            <v-icon dark>close</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="!cropperImg.imgSrc"
                            fab
                            small
                            bottom
                            right
                            :disabled="!selectedServices.length"
                            @click="onPickFile(1)"
                            absolute
                            style="z-index: 1"
                            color="green white--text"
                          >
                            <v-icon dark>add</v-icon>
                          </v-btn>
                        </v-card-text>
                      </v-card>
                      <br><br>
                      <v-card>
                        <v-card-text>
                          <img
                            :src="cropperSchoolImg.imgSrc"
                            v-if="cropperSchoolImg.imgSrc"
                            height="150"
                          />
                          <input
                            type="file"
                            style="display: none"
                            ref="schoolimage"
                            accept="image/*"
                            @change="onFilePicked"
                          />
                          <v-btn
                            v-if="cropperSchoolImg.imgSrc"
                            fab
                            small
                            bottom
                            right
                            absolute
                            @click.native.stop="resetImagePicker(2)"
                            style="z-index: 1"
                            color="red white--text"
                          >
                            <v-icon dark>close</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="!cropperSchoolImg.imgSrc"
                            fab
                            small
                            bottom
                            right
                            :disabled="!selectedServices.length"
                            @click="onPickFile(2)"
                            absolute
                            style="z-index: 1"
                            color="green white--text"
                          >
                            <v-icon dark>add</v-icon>
                          </v-btn>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="orange lighten-1" small>
                  <v-layout wrap pt-3>
                    <v-flex>
                      <strong>Contact and Address Info.</strong>
                      <div class="caption">
                        Company address and contact informations.
                      </div>
                      <v-flex pt-3 xs11>
                        <v-container style="padding: 0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex xs3>
                              <v-text-field
                                :disabled="!selectedServices.length"
                                solo
                                autocomplete="off"
                                label="Contact Person"
                                required
                                class="pa-0"
                                v-model="form.contact.contact_person"
                                name="person"
                              />
                            </v-flex>

                            <v-flex sm3>
                              <v-text-field
                                v-mask="'##########'"
                                solo
                                autocomplete="off"
                                label="Mobile"
                                :disabled="!selectedServices.length"
                                required
                                class="pa-0"
                                v-model="form.contact.primary_mobile"
                                name="mobile"
                              />
                            </v-flex>

                            <v-flex sm2>
                              <v-text-field
                                solo
                                autocomplete="off"
                                label="Phone"
                                :disabled="!selectedServices.length"
                                required
                                class="pa-0"
                                v-model="form.contact.primary_phone"
                                name="phone"
                              />
                            </v-flex>
                            <v-flex sm3>
                              <v-text-field
                                solo
                                autocomplete="off"
                                label="Secondary Phone"
                                :disabled="!selectedServices.length"
                                required
                                class="pa-0"
                                v-model="form.contact.secondary_phone"
                                name="phone"
                              />
                            </v-flex>

                            <v-flex sm4>
                              <v-text-field
                                solo
                                autocomplete="off"
                                label="Email"
                                :disabled="!selectedServices.length"
                                required
                                class="pa-0"
                                v-model="form.contact.primary_email"
                                name="phone"
                              />
                            </v-flex>
                            <v-flex xs3>
                              <v-text-field
                                solo
                                autocomplete="off"
                                label="FAX"
                                :disabled="!selectedServices.length"
                                required
                                class="pa-0"
                                v-model="form.contact.fax"
                                name="fax"
                              />
                            </v-flex>
                            <v-flex xs3>
                              <v-autocomplete
                                solo
                                :disabled="!selectedServices.length"
                                :items="designations"
                                v-model="form.contact.designation"
                                label="Designation"
                                data-vv-name="select"
                              />
                            </v-flex>

                            <v-flex xs12>
                              <v-layout>
                                <v-flex xs12 sm2>
                                  <v-autocomplete
                                    solo
                                    :disabled="!selectedServices.length"
                                    :items="states"
                                    v-model="
                                      company.addressInfo.data
                                        .permanent_state_id
                                    "
                                    label="Province/State"
                                    :rules="
                                      company.addressInfo.validation
                                        .permanent_state_id_rule
                                    "
                                    data-vv-name="select"
                                    required
                                  />
                                </v-flex>
                                <v-flex xs12 sm3>
                                  <v-autocomplete
                                    solo
                                    :disabled="
                                      !company.addressInfo.data
                                        .permanent_state_id
                                    "
                                    :items="permanent_districts"
                                    v-model="
                                      company.addressInfo.data
                                        .permanent_district_id
                                    "
                                    label="District"
                                    :rules="
                                      company.addressInfo.validation
                                        .permanent_district_id_rule
                                    "
                                    data-vv-name="select"
                                    required
                                  />
                                </v-flex>

                                <v-flex xs12 sm3>
                                  <v-autocomplete
                                    solo
                                    :disabled="
                                      !company.addressInfo.data
                                        .permanent_district_id
                                    "
                                    :items="permanent_cities"
                                    :rules="
                                      company.addressInfo.validation
                                        .permanent_city_id_rule
                                    "
                                    v-model="
                                      company.addressInfo.data.permanent_city_id
                                    "
                                    label="Municipality/City"
                                    data-vv-name="select"
                                    required
                                  />
                                </v-flex>
                                <v-flex xs12 sm1>
                                  <v-autocomplete
                                    solo
                                    :disabled="
                                      !company.addressInfo.data
                                        .permanent_city_id
                                    "
                                    :items="ward_count_permanent"
                                    :rules="
                                      company.addressInfo.validation
                                        .permanent_ward_no
                                    "
                                    v-model="
                                      company.addressInfo.data.permanent_ward_no
                                    "
                                    label="Ward"
                                    data-vv-name="select"
                                    required
                                  />
                                </v-flex>
                                <v-flex xs12 sm3 md3>
                                  <v-text-field
                                    solo
                                    label="Street/Tole"
                                    :disabled="
                                      !company.addressInfo.data
                                        .permanent_city_id
                                    "
                                    v-model="
                                      company.addressInfo.data
                                        .permanent_address_line
                                    "
                                    :rules="
                                      company.addressInfo.validation
                                        .permanent_address_line_rule
                                    "
                                  />
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex xs12 sm3 md3>
                              <v-text-field
                                solo
                                label="Logitude"
                                :disabled="!selectedServices.length"
                                v-model="form.address.lat"
                              />
                            </v-flex>

                            <v-flex xs12 sm3 md3>
                              <v-text-field
                                solo
                                label="Latitude"
                                :disabled="!selectedServices.length"
                                v-model="form.address.lon"
                              />
                            </v-flex>

                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                solo
                                label="Address"
                                :disabled="!selectedServices.length"
                                v-model="form.address_plain"
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="orange lighten-1" small>
                  <v-layout wrap pt-3>
                    <v-flex xs8>
                      <strong>Other Info.</strong>
                      <div class="caption">
                        Company registration and billing info.
                      </div>
                      <v-flex pt-3 xs11>
                        <v-container style="padding: 0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex xs4>
                              <v-text-field
                                :disabled="!selectedServices.length"
                                solo
                                autocomplete="off"
                                label="School Registration No."
                                required
                                class="pa-0"
                                v-model="form.other_info.regno"
                                name="person"
                              />
                            </v-flex>

                            <!--<v-flex xs12>-->
                            <v-flex sm3>
                              <!--{{form.billing}}-->
                              <v-text-field
                                solo
                                autocomplete="off"
                                label="Bill Generate Name"
                                :disabled="!selectedServices.length"
                                required
                                class="pa-0"
                                v-model="form.billing.name"
                                name="mobile"
                              />
                            </v-flex>
                            <v-flex sm3>
                              <v-text-field
                                solo
                                autocomplete="off"
                                label="TPIN"
                                :disabled="!selectedServices.length"
                                required
                                class="pa-0"
                                v-model="form.billing.tpin"
                                name="tpin"
                              />
                            </v-flex>

                            <v-flex sm8>
                              <v-text-field
                                solo
                                autocomplete="off"
                                label="Billing Address"
                                :disabled="!selectedServices.length"
                                required
                                class="pa-0"
                                v-model="form.billing.address"
                                name="phone"
                              />
                            </v-flex>
                            <v-flex sm8 v-if="$auth.isSuper()">
                              <v-text-field
                                solo
                                autocomplete="off"
                                label="Telegram Group Id"
                                :disabled="!selectedServices.length"
                                required
                                class="pa-0"
                                v-model="form.contact.telegram_group_id"
                                name="Telegram Id"
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                    <!-- <v-flex pt-2 xs3>
                      <v-card>
                        <v-card-text>
                          <img
                            :src="cropperSchoolImg.imgSrc"
                            v-if="cropperSchoolImg.imgSrc"
                            height="150"
                          />
                          <img
                            :src="'https://image.flaticon.com/icons/svg/138/138570.svg'"
                            height="150"
                            v-else
                          />
                          <input
                            type="file"
                            style="display: none"
                            ref="schoolimage"
                            accept="image/*"
                            @change="onFilePicked"
                          />
                          <v-btn
                            v-if="cropperSchoolImg.imgSrc"
                            fab
                            small
                            bottom
                            right
                            absolute
                            @click.native.stop="resetImagePicker(2)"
                            style="z-index: 1"
                            color="red white--text"
                          >
                            <v-icon dark>close</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="!cropperSchoolImg.imgSrc"
                            fab
                            small
                            bottom
                            right
                            :disabled="!selectedServices.length"
                            @click="onPickFile(2)"
                            absolute
                            style="z-index: 1"
                            color="green white--text"
                          >
                            <v-icon dark>add</v-icon>
                          </v-btn>
                        </v-card-text>
                      </v-card>
                    </v-flex> -->
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="orange lighten-1" small>
                  <v-layout wrap pt-3>
                    <v-flex>
                      <strong>Social Info.</strong>
                      <div class="caption">
                        Facebook, Youtube, Twitter, Instagram.
                      </div>
                      <v-flex pt-3 xs11>
                        <v-container style="padding: 0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex xs6>
                              <v-text-field
                                :disabled="!selectedServices.length"
                                solo
                                autocomplete="off"
                                label="Facebook"
                                required
                                class="pa-0"
                                v-model="form.social.facebook"
                                name="person"
                              />
                            </v-flex>
                            <v-flex xs6>
                              <v-text-field
                                :disabled="!selectedServices.length"
                                solo
                                autocomplete="off"
                                label="Youtube"
                                required
                                class="pa-0"
                                v-model="form.social.youtube"
                                name="person"
                              />
                            </v-flex>

                            <v-flex xs6>
                              <v-text-field
                                :disabled="!selectedServices.length"
                                solo
                                autocomplete="off"
                                label="Twitter"
                                required
                                class="pa-0"
                                v-model="form.social.twitter"
                                name="person"
                              />
                            </v-flex>

                            <v-flex xs6>
                              <v-text-field
                                :disabled="!selectedServices.length"
                                solo
                                autocomplete="off"
                                label="Instagram"
                                required
                                class="pa-0"
                                v-model="form.social.instagram"
                                name="person"
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="green lighten-1" small>
                  <v-layout wrap pt-3>
                    <v-flex>
                      <strong>About company.</strong>
                      <div class="caption">
                        Describe in brief about the company.
                      </div>
                      <v-flex pt-3 xs11>
                        <v-container style="padding: 0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex xs12>
                              <v-textarea
                                label="Description"
                                no-resize
                                rows="9"
                                v-model="form.description"
                                row-height="25"
                                outlined
                              ></v-textarea>
                              <!-- <wysiwyg
                                :disabled="!selectedServices.length"
                                v-model="form.description"
                              /> -->
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>

                <v-card-actions class="pl-5">
                  <!--<v-btn outlined color="warning">Cancel</v-btn>-->
                  <!-- <v-btn
                    outlined
                    color="success"
                    :disabled="!selectedServices.length"
                    @click="store"
                  >
                    Save
                  </v-btn> -->
                    <load-button :disabled="!selectedServices.length" @action="store" :loader="loader" color="success" text >Save</load-button>

                    <v-btn outlined color="error" @click="openDeactivateDialog=true">
                      Deactivate
                    </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-timeline>
            </v-form>
          </v-card-text>
          <!-- <v-card-text v-if="setVisible && v_errors.length > 0">
            <div v-for="(er,i) in v_errors" :key=i>
                <p style="color:red" >{{er}}</p>
            </div>
          </v-card-text> -->
          <v-card-text> </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="openDeactivateDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Deactivae School</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-4">
          <v-text-field
             
              type="password"
              autocomplete="off"
              label="Password"
              required
              class="pa-0"
              outlined
              dense
              v-model="user_password"
            />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="openDeactivateDialog=false,user_password=''"
            >Close</v-btn
          >
          <v-btn color="error" text @click="deactivateSchool() "  :disabled="user_password === '' ? true:false">Deactivate</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Form from "@/library/Form";
import statesData from "@/assets/json/states.json";
import districtDataData from "@/assets/json/districts.json";
import cityData from "@/assets/json/cities.json";
import { getPropertyFromArrayObject } from "@/library/helpers";
// import Loader from "../../../../components/Loader.vue";
import ImageCompressor from "image-compressor.js";

export default {
  // components: { Loader },

  data() {
    return {
      openDeactivateDialog:false,
      loader:false,
      cropperImg: {
        cropDialog: false,
        imgSrc: "",
        cropImg: "",
        uploadCropped: {
          url: "",
          image: "",
          cropped: "",
          file: "",
        },
        name: null,
      },
      cropperSchoolImg: {
        cropDialog: false,
        imgSrc: "",
        cropImg: "",
        uploadCropped: {
          url: "",
          image: "",
          cropped: "",
          file: "",
        },
        name: null,
      },
      form: new Form(
        {
          remove_school_image:false,
          name: "",
          short_code: "",
          registration_no: "",
          domain: "",
          slogan: "",
          description: "",
          logo: {},
          school_image: {},
          pdistrict_id: "",
          address: {
            lon: "",
            lat: "",
          },
          contact: {
            contact_person: "",
            primary_mobile: "",
            primary_phone: "",
            fax: "",
            primary_email: "",
            telegram_group_id: "",
            designation: "",
            secondary_phone:"",
          },
          social: {
            facebook: "",
            instagram: "",
            youtube: "",
            twitter: "",
          },
          other_info: {
            regno: "",
            representative: "",
          },
          billing: {
            name: "",
            tpin: "",
            address: "",
          },
          detail: "",
          merchant: "",
          service_id: "",
          pcity_id: "",
          pstreet: "",
          pstate_id: "",
          pward_no: "",
          designation: "",
          address_plain: "",
          established_date: "",
        },
        "/api/company"
      ),
      company: {
        validation: {
          valid: false,
          name: [(v) => !!v || "Is required"],
        },
        addressInfo: {
          data: {
            permanent_state_id: "",
            permanent_city_id: "",
            permanent_district_id: "",
            permanent_address_line: "",
            permanent_ward_no: "",
          },
          validation: {
            valid: false,
            permanent_state_id_rule: [(v) => !!v || "Please select state!"],
            permanent_ward_no: [(v) => !!v || "Ward no required!"],
            role: [(v) => !!v || "You must specify a role!"],
            permanent_address_line_rule: [(v) => !!v || "Address is required!"],
            permanent_district_id_rule: [
              (v) => !!v || "Please select district!",
            ],
            permanent_city_id_rule: [
              (v) => !!v || "Please select municipality/city!",
            ],
          },
        },
      },
      permanent_districts: [],
      permanent_cities: [],
      ward_count_permanent: [],
      selectedServices: [],
      services: [],
      states: [],
      pagination: {
        rowsPerPage: 50,
      },
      statesData,
      districtDataData,
      cityData,
      updateState: false,
      designations: ["Principle", "Accountant", "Teacher", "Administrator"],
      imageStatus: "",
      displayError: "",
      user_password:'',
    };
  },
  watch: {
    "form.name": {
      handler(name) {
        this.form.name = this.capitalizeString(name);
      },
    },
    "form.slogan": {
      handler(name) {
        this.form.slogan = this.capitalizeString(name);
      },
    },
    "form.short_code": {
      handler(name) {
        if (name) this.form.short_code = name.toUpperCase();
      },
    },
    "form.contact.person": {
      handler(name) {
        this.form.contact.person = this.capitalizeString(name);
      },
    },
    "company.addressInfo.data.permanent_address_line": {
      handler(name) {
        this.company.addressInfo.data.permanent_address_line =
          this.capitalizeString(name);
      },
    },
    "company.addressInfo.data.permanent_state_id": {
      handler(v) {
        this.getDistricts("permanent", v);
      },
    },
    "company.addressInfo.data.permanent_district_id": {
      handler(v) {
        this.getCities("permanent", v);
      },
    },
    "company.addressInfo.data.permanent_city_id": {
      handler(v) {
        this.getWardCount("permament");
      },
    },
    // "v_errors":{
    //   handler(v){
    //     setTimeout(() => {
    //       this.setVisible = false
    //     }, 8000);
    //     console.log("here form watch")
    //   }
    // }
  },
  mounted() {
    if (this.$route.params.id) {
      if (
        this.$auth.isSuper() ||
        this.$auth.company().id == this.$route.params.id
      ) {
        this.updateState = true;
        this.get();
      } else {
        this.$router.push({
          name: "schools-edit",
          params: { id: this.$auth.company().id },
        });
      }
    } else {
      this.updateState = false;
    }
    this.getServices();
    this.getStates();
  },
  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map((key) => {
            if (typeof json[key] == "object") {
              let vals = [...json[key]];
              if (vals.length)
                return (
                  encodeURIComponent(key) +
                  "=" +
                  encodeURIComponent(vals.join(","))
                );
              else return null;
            }
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
            );
          })
          .join("&")
      );
    },
    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.$rest
        .get("/api/company/" + this.$route.params.id + "?withService=true")
        .then((res) => {
          this.form.address_plain = res.data.address.address_plain;
          this.company.addressInfo.data.permanent_state_id = parseInt(
            res.data.address.state_id
          );
          this.company.addressInfo.data.permanent_city_id = parseInt(
            res.data.address.city_id
          );
          this.company.addressInfo.data.permanent_district_id = parseInt(
            res.data.address.district_id
          );
          this.company.addressInfo.data.permanent_address_line =
            res.data.address.address;
          this.company.addressInfo.data.permanent_ward_no = parseInt(
            res.data.address.ward_no
          );
          this.cropperImg.imgSrc = res.data.logo;
          this.cropperSchoolImg.imgSrc = res.data.school_image;

          //details
          if (res.data.detail && Object.keys(res.data.detail).length > 0) {
            this.form.other_info.regno = res.data.detail.regno;
            this.form.billing.name = res.data.detail.billing.name;
            this.form.billing.tpin = res.data.detail.billing.tpin;
            this.form.billing.address = res.data.detail.billing.address;
          }
          // this.form.pward_no = parseInt(this.company.addressInfo.data.permanent_ward_no);
          this.form.edit(res.data);

          this.selectedServices = res.data.services;
          this.form.established_date = res.data.established_date;
        });
    },
    getServices() {
      this.$rest.get("/api/service").then(({ data }) => {
        this.services = data.data;
      });
    },
    store() {
      if (this.$refs.companyInfoForm.validate()) {
        this.loader = true
        this.form.service_id = getPropertyFromArrayObject(
          "id",
          this.selectedServices
        ).join(",");
        this.form.pcity_id = this.company.addressInfo.data.permanent_city_id;
        this.form.pstate_id = this.company.addressInfo.data.permanent_state_id;
        this.form.pdistrict_id =
          this.company.addressInfo.data.permanent_district_id;
        this.form.pstreet =
          this.company.addressInfo.data.permanent_address_line;
        this.form.pward_no = this.company.addressInfo.data.permanent_ward_no;

        this.form.logo = this.cropperImg.file;
        this.form.school_image = this.cropperSchoolImg.file;
        this.form
          .store()
          .then(() => {
            if (this.$auth.isSuper()) {
              this.$router.push({ name: "schools" });
            }
             else {
              // this.$router.push("/");
              this.get();
            }
            this.form.remove_school_image = false;
          })
          .catch((err) => {
            let $this = this;
            if (err && err.data && err.data.errors) {
              $this.displayError = err.data;
              // Object.keys(err.data.errors).forEach(function (key) {
              //   $this.v_errors.push(err.data.errors[key][0])
              //   $this.$events.fire("notification", {
              //     message: err.data.errors[key][0],
              //     status: "error",
              //   });
              // });
            }
          }).finally(()=>{
            this.loader = false
          });
      }
    },

    getStates(type) {
      this.states = this.statesData.map((item) => {
        return { text: item.name, value: item.id };
      });
    },
    getDistricts(type, id) {
      this.permanent_districts = this.districtDataData
        .filter(
          (item) =>
            item.state_id === this.company.addressInfo.data.permanent_state_id
        )
        .map((item) => {
          return { value: item.id, text: item.name };
        });
      this.permanent_cities = [];
    },

    getCities(type, id) {
      this.permanent_cities = this.cityData
        .filter(
          (item) =>
            item.district_id ===
            this.company.addressInfo.data.permanent_district_id
        )
        .map((item) => {
          return { value: item.id, text: item.name };
        });
    },

    getWardCount(type) {
      let $this = this;
      let totalWards = [];
      if (type === "permament") {
        let city = this.cityData.filter(function (city, key) {
          return $this.company.addressInfo.data.permanent_city_id === city.id;
        });
        this.ward_count_permanent = [];
        // this.staff.addressInfo.data.permanent_ward_no = '';
        if (city.length) {
          city = city[0];
          let totalNumberOfWards = parseInt(city.ward_count);
          for (let i = 1; i <= totalNumberOfWards; i++) {
            totalWards.push(i);
          }
        }
        this.ward_count_permanent = totalWards;
      }
    },
    capitalizeString(string) {
      if (string === undefined || string === null) return "";
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    /**
     * Image Uploader
     */
    onPickFile(status) {
      if (status == 1) {
        this.imageStatus = 1;
        this.$refs.image.click();
      } else {
        this.imageStatus = 2;
        this.$refs.schoolimage.click();
      }
    },
    onFilePicked(e) {
      const files = e.target.files;
      let fileName = files[0].name;
      if (fileName.lastIndexOf(".") <= 0) {
        return;
      }

      if (typeof FileReader === "function") {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
        let $this = this;
        if (this.imageStatus == 1) {
          fileReader.addEventListener("load", () => {
            $this.cropperImg.url = fileReader.result;
            $this.cropperImg.name = fileName;
            $this.cropperImg.imgSrc = event.target.result;
            $this.cropperImg.file = files[0];

            // const imageCompressor = new ImageCompressor();
            // imageCompressor.compress(files[0], {quality: .5})
            //     .then((result) => {
            //         this.imagePicker.image = result
            //     })
            //     .catch((err) => {
            //         // Handle the error
            //     })
          });
        } else {
          fileReader.addEventListener("load", () => {
            $this.form.remove_school_image = false;
            $this.cropperSchoolImg.url = fileReader.result;
            $this.cropperSchoolImg.name = fileName;
            $this.cropperSchoolImg.imgSrc = event.target.result;
            $this.cropperSchoolImg.file = files[0];
            const imageCompressor = new ImageCompressor();
            imageCompressor
              .compress(files[0], { quality: 0.1 })
              .then((result) => {
                $this.cropperSchoolImg.file = result;
              })
              .catch((err) => {
                // Handle the error
              });
            });
        }

        // reader.onload = (event) => {
        //     this.cropperImg.name = file.name;
        // };
        // reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      this.cropperImg.cropDialog = true;
    },

    resetImagePicker(status) {
      if (status == 1) {
        this.cropperImg = {
          imgSrc: undefined,
          name: undefined,
          file: undefined,
        };
        this.$refs.image.value = "";
        this.cropperImg.cropImg = "";
        this.cropperImg.name = "";
      } else {
        this.cropperSchoolImg = {
          imgSrc: undefined,
          name: undefined,
          file: undefined,
        };
        this.$refs.image.value = "";
        this.cropperSchoolImg.cropImg = "";
        this.cropperSchoolImg.name = "";
        this.form.remove_school_image = true;
      }
    },
    deactivateSchool(){
      this.$rest.post('/api/deactivate-company',{
        password:this.user_password,
        company_id:this.$route.params.id
      }).then((res=>{
        if(res.status === 200){
          this.openDeactivateDialog = false;
          this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
          });
          this.user_password = '';
        }
      })).catch((err)=>{
        this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
      })
    }
  },
};
</script>

<style lang="scss">
.user_add_form {
  .v-chip,
  .v-chip .v-chip__content {
    border-radius: 2px;
    color: white;
  }
}
</style>
